import { useAuthStore } from "../../store/authStore";

type UsePermissionType = (params: { role: string }) => {
    hasAccess: boolean;
};

const usePermission: UsePermissionType = ({ role }) => {
    let hasAccess = false;
    const { user } = useAuthStore();

    if (!user) {
        return {
            hasAccess,
        };
    }

    return {
        hasAccess: role === user.role,
    };
};

export { usePermission };
