import { FC, SVGProps } from "react";

const KeyIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.5 2.5C13.7386 2.5 11.5 4.73858 11.5 7.5C11.5 7.89641 11.5461 8.28155 11.6329 8.65056C11.6646 8.78527 11.6245 8.9268 11.5267 9.02476L2.90898 17.6612C2.64708 17.9236 2.5 18.2793 2.5 18.65V21.0003C2.5 21.2764 2.72386 21.5003 3 21.5003H5.41143C5.78322 21.5003 6.13973 21.3524 6.40236 21.0893L6.6539 20.8372C6.74746 20.7435 6.8 20.6165 6.8 20.484V18C6.8 17.7791 6.97909 17.6 7.2 17.6H9.67731C9.81009 17.6 9.93742 17.5472 10.0312 17.4532L10.2539 17.2301C10.3475 17.1363 10.4 17.0093 10.4 16.8769V14.4C10.4 14.1791 10.5791 14 10.8 14H13.2702C13.403 14 13.5303 13.9472 13.6241 13.8532L14.9958 12.4788C15.0933 12.381 15.2344 12.3405 15.369 12.3716C15.732 12.4556 16.1106 12.5 16.5 12.5C19.2614 12.5 21.5 10.2614 21.5 7.5C21.5 4.73858 19.2614 2.5 16.5 2.5ZM16.4977 6.60156C16.0006 6.60156 15.5977 7.00451 15.5977 7.50156C15.5977 7.99862 16.0006 8.40156 16.4977 8.40156H17.0977C17.5947 8.40156 17.9977 7.99862 17.9977 7.50156C17.9977 7.00451 17.5947 6.60156 17.0977 6.60156H16.4977Z"
            fill="#C0C0CB"
        />
    </svg>
);

export { KeyIcon };
