import {
    DASHBOARD_ROUTE,
    EVENTS_ROUTE,
    CUSTOMERS_ROUTE,
    DEVICES_ROUTE,
    LISTS_ROUTE,
    RULES_ROUTE,
    ACCOUNT_SETTINGS_ROUTE,
    TEAM_MANAGEMENT_ROUTE,
    API_KEYS_ROUTE,
    LOGS_ROUTE,
} from "./routes";
import {
    ACCOUNT_SETTINGS,
    API_KEYS,
    CUSTOMERS,
    DASHBOARD,
    DEVICES,
    EVENTS,
    LISTS,
    LOGS,
    ROLES,
    RULES,
    TEAMS_MEMBERS,
} from "./constants";
import {
    CustomersIcon,
    DashboardIcon,
    ListIcon,
    LogsIcon,
    ShieldIcon,
    UserManageSolidIcon,
    WrenchScrewdriverSolidIcon,
} from "@moznsystems/mozn-ui";
import { FC, FunctionComponent, SVGProps } from "react";
import { RoleValues } from "@/types";
import { useAuthStore } from "@/store/authStore";
import { DeviceIcon } from "@/components/icons/device";
import { KeyIcon } from "@/components/icons/key";

export const GROUPS = {
    MAIN: "Main",
    CONFIGURATION: "Configuration",
    ORGANIZATION: "Organization",
    REPORTS: "Reports",
    DEVELOPER: "Developer",
};

export type NavigationType = {
    group: string;
    label: string;
    url: string;
    routes?: string[];
    role?: RoleValues;
    icon:
        | FunctionComponent<
              SVGProps<SVGSVGElement> & {
                  version?: "1" | "2";
              }
          >
        | FC<SVGProps<SVGSVGElement>>;
    subItems: SubNavigationType[];
};

type SubNavigationType = {
    label: string;
    url: string;
    role?: keyof typeof ROLES;
    routes: string[];
};

const navigation: NavigationType[] = [
    {
        group: GROUPS.MAIN,
        label: DASHBOARD,
        url: DASHBOARD_ROUTE,
        icon: DashboardIcon,
        subItems: [],
    },
    {
        group: GROUPS.MAIN,
        label: EVENTS,
        url: EVENTS_ROUTE,
        icon: ShieldIcon,
        subItems: [],
    },
    {
        group: GROUPS.MAIN,
        label: CUSTOMERS,
        url: CUSTOMERS_ROUTE,
        icon: CustomersIcon,
        subItems: [],
    },
    {
        group: GROUPS.MAIN,
        label: DEVICES,
        url: DEVICES_ROUTE,
        icon: DeviceIcon,
        subItems: [],
    },
    {
        group: GROUPS.CONFIGURATION,
        label: RULES,
        url: RULES_ROUTE,
        role: ROLES.ADMIN,
        icon: WrenchScrewdriverSolidIcon,
        subItems: [],
    },
    {
        group: GROUPS.CONFIGURATION,
        label: LISTS,
        url: LISTS_ROUTE,
        icon: ListIcon,
        subItems: [],
    },
    {
        group: GROUPS.ORGANIZATION,
        label: ACCOUNT_SETTINGS,
        url: ACCOUNT_SETTINGS_ROUTE,
        icon: UserManageSolidIcon,
        subItems: [],
    },
    {
        group: GROUPS.ORGANIZATION,
        label: TEAMS_MEMBERS,
        url: TEAM_MANAGEMENT_ROUTE,
        role: ROLES.ADMIN,
        icon: CustomersIcon,
        subItems: [],
    },
    {
        group: GROUPS.REPORTS,
        label: LOGS,
        url: LOGS_ROUTE,
        role: ROLES.ADMIN,
        icon: LogsIcon,
        subItems: [],
    },
    {
        group: GROUPS.DEVELOPER,
        label: API_KEYS,
        url: API_KEYS_ROUTE,
        role: ROLES.ADMIN,
        icon: KeyIcon,
        subItems: [],
    },
];

const canBeIncludedInNavList = (
    role: string,
    navItem: NavigationType | SubNavigationType,
) => {
    if (navItem.role) {
        return role === navItem.role;
    }
    return true;
};

export const getNavigationItems = () => {
    const { user } = useAuthStore();

    return navigation
        .filter((navItem) => canBeIncludedInNavList(user?.role, navItem))
        .map((navItem) => {
            if (navItem.subItems) {
                const filteredSubItems = navItem.subItems.filter((subItem) =>
                    canBeIncludedInNavList(user?.role, subItem),
                );
                navItem.subItems = filteredSubItems;
            }
            return navItem;
        });
};
