import { Fragment, useState } from "react";
import {
    getNavigationItems,
    GROUPS,
    NavigationType,
} from "@/constants/navigation";
import { NavLink, useLocation, useMatch } from "react-router-dom";
import { CollapseButton, SidebarStatusActionTypes } from "@moznsystems/mozn-ui";
import { motion } from "framer-motion";
import { useSidebarStatusContext } from "@/store/sidebar-status-context";

type SideBarProps = {
    setDrawerVisible: (status: boolean) => void;
};

const headerTabVariants = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.05 },
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
};

const headerSubTabVariants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 },
        },
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 },
        },
    },
};

export const SideBar = ({ setDrawerVisible }: SideBarProps) => {
    const location = useLocation();
    const active = location?.pathname?.split("/").join(",/").split(",");
    const [expandedItem, setExpandedItem] = useState<string | undefined>("/");
    const { sidebarStatus, handleSidebarStatus } = useSidebarStatusContext();

    let previousGroup = "";
    const toggleExpandItem = (url: string) => {
        setExpandedItem((prev) => (prev === url ? undefined : url));
    };

    return (
        <ul className="side-bar__nav__group dashboard_menu">
            {getNavigationItems().map((i: NavigationType, index) => {
                const showGroup = i.group !== previousGroup;
                previousGroup = i.group;

                return (
                    <Fragment key={index}>
                        {showGroup && i.group !== GROUPS.MAIN && (
                            <div className="side-bar__nav__group">
                                <p className="side-bar__nav__group--title">
                                    {i.group}
                                </p>
                            </div>
                        )}
                        <li
                            className={
                                "side-bar__nav__item" +
                                (active.indexOf(i.url) !== -1
                                    ? " side-bar__nav__item--active"
                                    : "")
                            }
                            key={`side-bar__nav__item-${index}`}
                        >
                            <div className="side-bar__nav__item--header">
                                {i.subItems.length > 0 ? (
                                    <>
                                        <div
                                            className="side-bar__nav__item--main"
                                            key={i.url}
                                            onClick={() => {
                                                if (!i.subItems?.length) {
                                                    setDrawerVisible(false);
                                                }
                                                toggleExpandItem(i.url);
                                            }}
                                        >
                                            <div>
                                                {i.icon && (
                                                    <i.icon className="side-bar__nav__item__icon" />
                                                )}
                                                <span>{i.label}</span>
                                            </div>
                                        </div>
                                        <div
                                            onClick={(event) =>
                                                event.stopPropagation()
                                            }
                                        >
                                            <CollapseButton
                                                collapsed={
                                                    expandedItem !== i.url
                                                }
                                                toggleCollapse={() =>
                                                    toggleExpandItem(i.url)
                                                }
                                                iconStyle
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <NavLink
                                        key={i.url}
                                        onClick={() => {
                                            setDrawerVisible(false);
                                        }}
                                        className="side-bar__nav__item--main"
                                        to={i.url}
                                    >
                                        <div>
                                            {i.icon && (
                                                <i.icon className="side-bar__nav__item__icon" />
                                            )}
                                            <span>{i.label}</span>
                                        </div>
                                    </NavLink>
                                )}
                            </div>
                            {i.subItems && i.subItems.length > 0 ? (
                                <motion.ul
                                    variants={headerTabVariants}
                                    initial={false}
                                    animate={
                                        expandedItem === i.url
                                            ? "open"
                                            : "closed"
                                    }
                                    className={
                                        expandedItem === i.url
                                            ? "nav--expanded"
                                            : ""
                                    }
                                >
                                    {i.subItems.map((subItem, index) => {
                                        let match = false;
                                        subItem?.routes?.forEach((routeURL) => {
                                            const isMatch = useMatch({
                                                path: routeURL,
                                                end: true,
                                            });
                                            if (isMatch) match = true;
                                        });

                                        return (
                                            <motion.li
                                                variants={headerSubTabVariants}
                                                key={index}
                                                className={
                                                    "side-bar__nav__sub_item" +
                                                    (match
                                                        ? " side-bar__nav__sub_item--active"
                                                        : "")
                                                }
                                            >
                                                <NavLink
                                                    onClick={() => {
                                                        setDrawerVisible(false);
                                                        if (
                                                            sidebarStatus ===
                                                            SidebarStatusActionTypes.TEMPORARILY_OPEN_SIDEBAR
                                                        ) {
                                                            handleSidebarStatus(
                                                                SidebarStatusActionTypes.CLOSE_SIDEBAR,
                                                            );
                                                        }
                                                    }}
                                                    to={subItem.url}
                                                >
                                                    <span>{subItem.label}</span>
                                                </NavLink>
                                            </motion.li>
                                        );
                                    })}
                                </motion.ul>
                            ) : null}
                        </li>
                    </Fragment>
                );
            })}
        </ul>
    );
};
