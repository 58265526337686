export const EVENTS_ROUTE = "/events";
export const EVENT_DETAILS = (UUID = ":uuid") => `${EVENTS_ROUTE}/${UUID}`;
export const DASHBOARD_ROUTE = "/dashboard";
export const CUSTOMERS_ROUTE = "/customers";
export const CUSTOMERS_DETAILS = (UUID = ":uuid") =>
    `${CUSTOMERS_ROUTE}/${UUID}`;
export const DEVICES_ROUTE = "/devices";
export const DEVICES_DETAILS = (UUID = ":uuid") => `${DEVICES_ROUTE}/${UUID}`;
export const LISTS_ROUTE = "/lists";
export const LISTS_DETAILS_ROUTE = (UUID = ":uuid") => `${LISTS_ROUTE}/${UUID}`;

export const ACCOUNT_SETTINGS_ROUTE = `/account-settings`;
export const TEAM_MANAGEMENT_ROUTE = `/team-management`;
export const API_KEYS_ROUTE = `/api-keys`;

export const LOGS_ROUTE = `/logs`;
export const LOGS_EVENT_REPORTS_ROUTE = `${LOGS_ROUTE}/event-reports`;
export const LOGS_AUDIT_TRAIL_ROUTE = `${LOGS_ROUTE}/audit-trail`;

export const RULES_ROUTE = `/configuration`;
export const RULES_THRESHOLD_ROUTE = `${RULES_ROUTE}/event-threshold`;
export const RULES_EVENTS_ROUTE = `${RULES_ROUTE}/event-rules`;

export const NOT_FOUND_ROUTE = "/404";

export const FOCAL_ROUTE_STAGING = "https://staging.sa.focal.mozn.sa";
export const FOCAL_ROUTE_PROD = "https://app.sa.focal.mozn.sa";
