import { Suspense, lazy, useEffect } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { ToastProvider } from "../components/toast/toast-provider";
import { Layout } from "../layout/Layout";
import { useAuthStore } from "../store/authStore";
import {
    EVENTS_ROUTE,
    DASHBOARD_ROUTE,
    NOT_FOUND_ROUTE,
    EVENT_DETAILS,
    CUSTOMERS_ROUTE,
    CUSTOMERS_DETAILS,
    DEVICES_ROUTE,
    DEVICES_DETAILS,
    ACCOUNT_SETTINGS_ROUTE,
    LISTS_ROUTE,
    LISTS_DETAILS_ROUTE,
    RULES_EVENTS_ROUTE,
    RULES_ROUTE,
    RULES_THRESHOLD_ROUTE,
    TEAM_MANAGEMENT_ROUTE,
    API_KEYS_ROUTE,
    LOGS_ROUTE,
    LOGS_EVENT_REPORTS_ROUTE,
    LOGS_AUDIT_TRAIL_ROUTE,
} from "../constants/routes";
import { Loader } from "@/components/loader/loader";
import SidebarStatusProvider from "@/store/sidebar-status-context";
import { usePermission } from "@/hooks/usePermissions";
import { ROLES } from "@/constants/constants";

const Events = lazy(() => import("./Events"));
const EventDetails = lazy(() => import("./Events/event-details"));
const Dashboard = lazy(() => import("./Dashboard"));
const Customers = lazy(() => import("./Customers"));
const CustomerDetails = lazy(() => import("./Customers/customer-details"));
const Devices = lazy(() => import("./Devices/devices"));
const DeviceDetails = lazy(() => import("./Devices/device-details"));
const ListDetails = lazy(() => import("./Configuration/Lists/list-details"));
const Lists = lazy(() => import("./Configuration/Lists"));
const Rules = lazy(() => import("./Configuration/Rules/Rules"));
const AccountSettings = lazy(() => import("./AccountSettings"));
const TeamManagement = lazy(() => import("./TeamManagement"));
const APIKeys = lazy(() => import("./ApiKeys"));
const Threshold = lazy(() => import("./Configuration/Rules/Threshold"));
const EventReports = lazy(() => import("./Logs/EventReports"));
const AuditTrail = lazy(() => import("./Logs/AuditTrail"));
const NotFoundPage = lazy(() => import("./NotFoundPage"));

export const Main = ({ authenticated, userData }: any) => {
    const { setIsAuthentecated, setUser } = useAuthStore();
    const { hasAccess: hasAdminAccess } = usePermission({ role: ROLES.ADMIN });

    useEffect(() => {
        setIsAuthentecated(authenticated);
        setUser({
            id: userData.sub,
            email: userData.email,
            first_name: userData.given_name,
            last_name: userData.family_name,
            role: userData.resource_access?.["fraud"]?.roles?.[0] || [],
            email_verified: userData.email_verified,
            created_at: userData.created_timestamp,
            tenant: {
                id: userData.tenant_id,
                name: userData.tenant_name,
            },
        });
    }, []);

    return (
        <ToastProvider>
            <SidebarStatusProvider>
                <Layout>
                    <Suspense
                        fallback={
                            <div className="loader-container loader-container--center loader-container--full-height">
                                <Loader size="50" />
                            </div>
                        }
                    >
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <Navigate to={DASHBOARD_ROUTE} replace />
                                }
                            />

                            <Route
                                path={DASHBOARD_ROUTE}
                                element={<Dashboard />}
                            />

                            <Route path={EVENTS_ROUTE} element={<Events />} />
                            <Route
                                path={EVENT_DETAILS()}
                                element={<EventDetails />}
                            />

                            <Route
                                path={CUSTOMERS_ROUTE}
                                element={<Customers />}
                            />
                            <Route
                                path={CUSTOMERS_DETAILS()}
                                element={<CustomerDetails />}
                            />

                            <Route path={DEVICES_ROUTE} element={<Devices />} />
                            <Route
                                path={DEVICES_DETAILS()}
                                element={<DeviceDetails />}
                            />

                            <Route path={LISTS_ROUTE} element={<Lists />} />
                            <Route
                                path={LISTS_DETAILS_ROUTE()}
                                element={<ListDetails />}
                            />

                            <Route
                                path={ACCOUNT_SETTINGS_ROUTE}
                                element={<AccountSettings />}
                            />

                            {hasAdminAccess && (
                                <>
                                    <Route
                                        path={TEAM_MANAGEMENT_ROUTE}
                                        element={<TeamManagement />}
                                    />
                                    <Route
                                        path={RULES_ROUTE}
                                        element={
                                            <Navigate
                                                to={RULES_EVENTS_ROUTE}
                                                replace
                                            />
                                        }
                                    />
                                    <Route
                                        path={RULES_EVENTS_ROUTE}
                                        element={<Rules />}
                                    />
                                    <Route
                                        path={RULES_THRESHOLD_ROUTE}
                                        element={<Threshold />}
                                    />

                                    <Route
                                        path={API_KEYS_ROUTE}
                                        element={<APIKeys />}
                                    />

                                    <Route
                                        path={LOGS_ROUTE}
                                        element={
                                            <Navigate
                                                to={LOGS_EVENT_REPORTS_ROUTE}
                                                replace
                                            />
                                        }
                                    />
                                    <Route
                                        path={LOGS_EVENT_REPORTS_ROUTE}
                                        element={<EventReports />}
                                    />

                                    <Route
                                        path={LOGS_AUDIT_TRAIL_ROUTE}
                                        element={<AuditTrail />}
                                    />
                                </>
                            )}

                            <Route
                                path={NOT_FOUND_ROUTE}
                                element={<NotFoundPage />}
                            />

                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </Suspense>
                </Layout>
            </SidebarStatusProvider>
        </ToastProvider>
    );
};

export default Main;
